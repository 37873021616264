import React, { Component } from 'react';
import ReactGA from 'react-ga';

class ListStonks extends Component {
  state = { 
      stonks_list : [],
      filtered_list : [],
      short_list: [],
      render_searchbar : false
    }

  trackInterest = (symbol) => {
    ReactGA.event({
      category: "interest",
      action: "view-stonk",
      label: symbol
    })
  }

  filterList = (e) =>  {
    const query = e.target.value.toLowerCase()
    let newlist = this.state.stonks_list.filter(stock => {
      return (
        stock.Symbol.toLowerCase().search(query) !== -1 ||
        stock.Security.toLowerCase().search(query) !== -1
      )
    })
    let shortlist = newlist.filter((stock, index) => index < 6)
    this.setState({
      filtered_list : newlist,
      short_list : shortlist
    })
  }
  
  componentDidMount() {
    fetch(`https://stonks-lambdas-git-master.drg.now.sh/api/list-stonks.js`)
    .then(res => res.json())
    .then((data) => {
      this.setState({
          stonks_list : data.stocks,
          render_searchbar: true
        })
      })
      .catch(console.log)
  }

  render() {
    return (
      <div className="stonk-list">
          <div className="searchbar-wrapper">
        {
          this.state.render_searchbar &&
          <form>
            <input 
              className="App-input"
              type="text" 
              placeholder="Find your favorite stonks!" 
              onChange={this.filterList}/>
          </form>
        }
          {
            this.state.short_list.map(item => {
              return(<div key={item.Symbol}>
                <a className="App-link stonk-link" onClick={this.trackInterest(item.Symbol)} href={`/stonk/${item.Symbol}`}>
                  {`${item.Symbol} | ${item.Security}`}
                </a>
                </div>)
            })
          }
        </div>
      </div>
    );
  }
}

export default ListStonks;