import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import { createBrowserHistory } from 'history';
import CheckStonk from './Components/CheckStonk';
import ListStonks from './Components/ListStonks';
import Header from './Components/Header';
import Share from './Components/Share';
//import TwitterRoute from './Components/TwitterRoute';

const history = createBrowserHistory();

const trackingID = 'UA-162166030-1'
ReactGA.initialize(trackingID, {
  gaOptions : {
    siteSpeedSampleRate : 100 //Send 100% of hits to GA for timings
  }
})

ReactGA.event({
  category: "interest",
  action: "view-stonk",
  label: "main-page"
})

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


class App extends Component {
  
  alphaVantage = (e) => {
    ReactGA.event({
      category: "navigation",
      action: "clickout",
      label: "alpha-vantage"
    })
  }
  
  getLocation = () => {
    return history.location.pathname;
  }
  
  render() {
    return (        
      <div className="App">
        <BrowserRouter>
          <div className="App-header">
            <Header />
            <Switch>
              <Route exact path="/stonk/:ticker" component={CheckStonk} isTweet={false} /> 
              <Route exact path="/t/:ticker" render={(props) => <CheckStonk {...props} isTweet={true} />} /> 
              <Route exact path="/" render={(props) => <CheckStonk ticker="SPY" {...props} />} /> 
              <Route render={() => <Redirect to="/" />} />
            </Switch>
            <ListStonks />
            <p className="small">CheckStonks is powered by
            <a href="https://www.alphavantage.co/" onClick={this.alphaVantage} className="App-link"> Alpha Vantage</a>! 
            Go check them out if you code.</p>
            <Share shareUrl={this.getLocation} />
          </div>
        </BrowserRouter>
    </div>
  )
  };
}

export default App;