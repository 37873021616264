import React, { Component } from 'react';
import ReactGA from 'react-ga';

class Header extends Component {

  covid = (e) => {
    ReactGA.event({
      category: "navigation",
      action: "clickout",
      label: "covid-advice"
    })
  }

  crypto = (e) => {
    ReactGA.event({
      category: "navigation",
      action: "clickout",
      label: "covid-advice"
    })
  }

  homepage = (e) => {
    ReactGA.event({
      category: "navigation",
      action: "clickin",
      label: "homepage-header"
    })
  }

  render() {
    return (
      <header className="lean-header">
        <ul className="header-list">
          <li><a 
          href="/" 
          className="App-link header-link"
          onClick={this.homepage}>Stonks</a></li>
          <li><a 
          href="https://coinmarketcap.com/" 
          className="App-link header-link"
          onClick={this.crypto}>Crypto</a></li>
          <li><a 
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public" 
          className="App-link header-link"
          onClick={this.covid}>COVID Advice</a></li>
        </ul>
      </header>
    );
  }
}

export default Header;