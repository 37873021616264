import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {
  EmailShareButton,
  FacebookShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  EmailIcon,
  FacebookIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  VKIcon,
  WhatsappIcon,
  WeiboIcon
} from "react-share";

const icon_size = 28;
const utm_campaign = 'social';
const utm_medium = 'share_button'

class Share extends Component {
  state = {
    share_url : 'https://checkstonks.com/'
  }

  trackShare = (tag) => {
    ReactGA.event({
      category: "referral",
      action: "share-social",
      label: `share-${tag}`
    })
  }

  getTicker = () => {
    return this.props.shareUrl().split("/")[2];
  }

  componentDidMount () { 
    this.setState({
      share_url: `https://checkstonks.com${this.props.shareUrl()}`,
      share_ticker : (
        this.props.shareUrl() === "/" || this.props.shareUrl() === "/stonk/INX" || this.props.shareUrl() === "/stonk/SPX"
      ) ? "S&P 500" : this.getTicker(),
      share_ticker_tw : (
        this.props.shareUrl() === "/" || this.props.shareUrl() === "/stonk/INX" || this.props.shareUrl() === "/stonk/SPX"
      ) ? "SPX" : this.getTicker(),
      share_twitter_url : `https://checkstonks.com/t/${(
        this.props.shareUrl() === "/" || this.props.shareUrl() === "/stonk/INX" || this.props.shareUrl() === "/stonk/SPX"
      ) ? "SPX" : this.getTicker()}`
      
      //${this.state.share_url}?utm_source=twitter_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}
  })
  }

  render () {
    return (
      <div className="share-list">
        <FacebookShareButton 
          url={`${this.state.share_url}?utm_source=facebook_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          quote={`I'm checking out today's stonks, including ${this.state.share_ticker} on checkstonks.com!`}
          hashtag={'#stonks'}
          > 
          <FacebookIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('facebook')} />
        </FacebookShareButton>
        {/* TODO: Add ticker price to tweets */}
        <TwitterShareButton 
          url={this.state.share_twitter_url}
          title={`I'm checking out ${this.state.share_ticker} stonks on ${this.state.share_twitter_url}!`}
          via={'checkstonks'}
          hashtags={['stonks', `${this.state.share_ticker_tw}`]}
          related={['@checkstonks']}
          > 
          <TwitterIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('twitter')} />
        </TwitterShareButton>
        <WhatsappShareButton 
          url={`${this.state.share_url}?utm_source=whatsapp_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title={`I'm checking out ${this.state.share_ticker} stonks today!`}
          > 
          <WhatsappIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('whatsapp')} />
        </WhatsappShareButton>
        <TelegramShareButton 
          url={`${this.state.share_url}?utm_source=telegram_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title={`${this.state.share_ticker} Stonks Today!`}
          > 
          <TelegramIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('telegram')} />
        </TelegramShareButton>
        <VKShareButton 
          url={`${this.state.share_url}?utm_source=vk_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title={`I'm checking out ${this.state.share_ticker} stonks today!`}
          image={process.env.PUBLIC_URL+'/Stonks.jpeg'}
          > 
          <VKIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('vk')} />
        </VKShareButton>
        <RedditShareButton 
          url={`${this.state.share_url}?utm_source=reddit_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title={`Check out ${this.state.share_ticker} stonks today!`}
          > 
          <RedditIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('reddit')} />
        </RedditShareButton>
        <EmailShareButton 
          url={`${this.state.share_url}?utm_source=email_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          subject={`Check out ${this.state.share_ticker} stonks!`}
          body={`I'm checking out today's stonks, including ${this.state.share_ticker} on checkstonks.com! You can view them on: `}
          > 
          <EmailIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('email')} />
        </EmailShareButton>
        <TumblrShareButton 
          url={`${this.state.share_url}?utm_source=tumblr_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title="Check Stonks!"
          caption={`Check out ${this.state.share_ticker} stonks today!`}
          tags={["stonks", "checkstonks"]}
          > 
          <TumblrIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('tumblr')} />
        </TumblrShareButton>
        <PocketShareButton 
          url={`${this.state.share_url}?utm_source=pocket_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title={`Check out ${this.state.share_ticker} stonks today!`}
          > 
          <PocketIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('pocket')} />
        </PocketShareButton>
        <WeiboShareButton 
          url={`${this.state.share_url}?utm_source=weibo_social&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`}
          title={`I'm checking out ${this.state.share_ticker} stonks today!`}
          image={process.env.PUBLIC_URL+'/Stonks.jpeg'}
          > 
          <WeiboIcon size={icon_size} 
          round={true} 
          className="social-icons"
          onClick={() => this.trackShare('weibo')} />
        </WeiboShareButton>
      </div>
    )
  }
}

export default Share;