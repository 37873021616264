import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet }  from 'react-helmet';

let stonks_route = process.env.PUBLIC_URL+'/Stonks.jpeg'
let not_stonks_route = process.env.PUBLIC_URL+'/NotStonks.jpg'

let round2Dec = num => (Math.round(num*100)/100)

class CheckStonk extends Component {
  state = { 
    render_stonk : false,
    stonk_selector : "Stonks",
    stonk_route : stonks_route
  }
  
  componentDidMount() {
    const { params } = this.props.match;
    
    let ticker = params.ticker || this.props.ticker || "SPY" //S&P 500

    fetch(`https://stonks-lambdas-git-master.drg.now.sh/api/check-stonk.js?ticker=${ticker}`)
      .then(res => res.json())
      .then((data) => {
        let last_prices = data['Time Series (Daily)'][Object.keys(data['Time Series (Daily)'])[0]]
        let last_open = last_prices['1. open']
        let last_close = last_prices['4. close']
        let roc = (last_close-last_open)/last_open
        this.setState({
          ticker : ticker,
          stonk_selector: (roc >= 0) ? "Stonks" : "Not Stonks",
          stonk_route: (roc >= 0) ? stonks_route : not_stonks_route,
          close_price : round2Dec(last_close),
          open_price : round2Dec(last_open),
          roc : parseFloat(roc).toPrecision(3),
          render_stonk : true
          })
        if (this.props.isTweet) { //Attribute to twitter
          console.log(`twitter-${this.state.ticker}`)
          ReactGA.event({
            category: "referral",
            action: "view-stonk",
            label: `twitter-${this.state.ticker}`
          })
        }
      })
      .catch(console.log)
  }

  render() {
    return (
      <div className="check-stonk">
        <Helmet>
          <title>Check {(this.state.ticker === "SPY") ? "S&P 500" : this.state.ticker || ""} Stonks!</title>
          <meta name="description" 
          content={`Check out the latest stonks results for ${(this.state.ticker === "SPY") ? "S&P 500" : this.state.ticker || ""}!`} />
        </Helmet>
        {
          this.state.render_stonk &&
            <img src={this.state.stonk_route} className="App-logo" alt="logo" />
        }
        {
          this.state.render_stonk &&
        <div className="text-container">
          <p>
            Today's mood {(this.state.ticker === "SPY") ? "" : `for ${this.state.ticker}`} is: {this.state.stonk_selector}!
          </p>
          <p>
            Yesterday {(this.state.ticker === "SPY") ? "the S&P 500 Index" : this.state.ticker} closed at {this.state.close_price},
            a difference of {round2Dec(this.state.close_price - this.state.open_price)} relative
            to the last day.
          </p>
          <p>
            This gave a yield of {parseFloat(this.state.roc * 100).toPrecision(3) + '%'}.
          </p>
        </div>
        }
      </div>
    );
  }
}

export default CheckStonk;